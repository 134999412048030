import {Route, Routes} from 'react-router-dom';
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react'
import { BrowserRouter } from 'react-router-dom';
import {persistor} from './redux/store'

import './App.scss';
import Footer from './Components/Footer';
import { useEffect, useState } from 'react';
import LoginModal from './Components/Modals/Login';
import RegistrationModal from './Components/Modals/Registration';
import DownloadModal from './Components/Modals/Download';
import PreloaderModal from './Components/Modals/PreloaderModal';
import ForgetModal from './Components/Modals/Forget';

import Professionals from './Pages/Professionals';
import UserAgreements from './Pages/UserAgreements'
import News from './Pages/News';
import Services from './Pages/Services';
import AboutUs from './Pages/AboutUs';
import Contacts from './Pages/Contacts';
import React from 'react';
import Preloader from './Components/Preloader'
import Main from './Pages/Main'
import Privacy from './Pages/Privacy';
import Faq from './Pages/Faq';
import HowWorks from './Pages/HowWorks';
import Download from "./Pages/Download";

import { useTranslation, Trans } from "react-i18next";
import detectBrowserLanguage from 'detect-browser-language'

import { Toaster } from 'react-hot-toast';

function App() {

  const [LoginModalState, toggleLoginModal] = useState(false);
  const [RegistrationModalState, toggleRegistrationModal] = useState(false);
  const [DownloadModalState, toggleDownloadModalState] = useState(false);
  const [PreloaderModalState, togglePreloaderModalState] = useState(false);
  const [ForgetModalState, toggleForgetModalState] = useState(false);
  const { t, i18n } = useTranslation();

  const [loader, setLoader] = useState(true);

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, [])

  const onBeforeLift = (e) => {
    if(e === undefined) {
      setTimeout(() => {
        setLoader(false);

        if(localStorage.getItem('preloader') !== 'true') {
          const lang = detectBrowserLanguage();
          if(lang.indexOf('en') >= 0 || lang.indexOf('ar') >= 0 || lang.indexOf('az') >= 0 || lang.indexOf('ge') >= 0 || lang.indexOf('ru') >= 0 || lang.indexOf('tr') >= 0) {
            console.log(true);
            i18n.changeLanguage(lang)
          }

          togglePreloaderModalState(true);
        }
      }, 2500)
    }

    // try to read current query page
    const searchParams = new URLSearchParams(document.location.search)

    if(searchParams) {
      console.log(searchParams.get('page'))

      switch (searchParams.get('page')) {
        case 'contacts':
          // open contacts page
          window.location.href = '/contacts'
          break;
        case 'user-agreements':
          // open user-agreements page
          window.location.href = '/user-agreements'
          break;
        case 'privacy':
          // open privacy page
          window.location.href = '/privacy'
          break;
        case 'faq':
          // open faq page
          window.location.href = '/faq'
          break;
      }
    }
  }

  return (
    <div className='App'>
      {
        loader &&
        <Preloader/>
      }
      <PersistGate onBeforeLift={onBeforeLift} loading={null} persistor={persistor}>
        <BrowserRouter>
            {
              PreloaderModalState && 
              <PreloaderModal onClose={togglePreloaderModalState} />
            }
            {
              ForgetModalState && 
              <ForgetModal toggleLoginModal={toggleLoginModal} onClose={toggleForgetModalState} />
            }
            {
              DownloadModalState && false &&
              <DownloadModal onClose={toggleDownloadModalState} />
            }
            {
              LoginModalState &&
              <LoginModal toggleRegistrationModal={toggleRegistrationModal} toggleDownloadModalState={toggleDownloadModalState} onClose={toggleLoginModal} toggleForgetModalState={toggleForgetModalState} />
            }
            {
              RegistrationModalState &&
              <RegistrationModal toggleDownloadModalState={toggleDownloadModalState} toggleLoginModal={toggleLoginModal} onClose={toggleRegistrationModal} />
            }
            <Routes>
              <Route path="/" element={<Main toggleDownloadModalState={toggleDownloadModalState} onChangeLogin={toggleLoginModal} onChangeRegistration={toggleRegistrationModal}/>} />
              <Route path="/news" element={<News onChangeLogin={toggleLoginModal} onChangeRegistration={toggleRegistrationModal} />} />
              <Route path="/services" element={<Services toggleDownloadModalState={toggleDownloadModalState} onChangeLogin={toggleLoginModal} onChangeRegistration={toggleRegistrationModal} />} />
              <Route path="/about-us" element={<AboutUs toggleDownloadModalState={toggleDownloadModalState} onChangeLogin={toggleLoginModal} onChangeRegistration={toggleRegistrationModal} />} />
              <Route path="/contacts" element={<Contacts onChangeLogin={toggleLoginModal} onChangeRegistration={toggleRegistrationModal} />} />
              <Route path="/user-agreements" element={<UserAgreements onChangeLogin={toggleLoginModal} onChangeRegistration={toggleRegistrationModal} />} />
              <Route path="/privacy" element={<Privacy onChangeLogin={toggleLoginModal} onChangeRegistration={toggleRegistrationModal} />} />
              <Route path="/faq" element={<Faq onChangeLogin={toggleLoginModal} onChangeRegistration={toggleRegistrationModal} />} />
              <Route path="/how-works" element={<HowWorks onChangeLogin={toggleLoginModal} onChangeRegistration={toggleRegistrationModal} />} />
              <Route path="/professional" element={<Professionals toggleDownloadModalState={toggleDownloadModalState} onChangeLogin={toggleLoginModal} onChangeRegistration={toggleRegistrationModal} />} />
              <Route path="/download" element={<Download toggleDownloadModalState={toggleDownloadModalState} onChangeLogin={toggleLoginModal} onChangeRegistration={toggleRegistrationModal} />} />

              <Route path='*' element={<p>page not found</p>}/>
            </Routes>
            <Toaster  position="top-right" />
            <Footer />
        </BrowserRouter>
      </PersistGate>
    </div>
  );
}

export default App;
